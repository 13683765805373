import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCustomerQuery } from "../../../redux/features/customers/customersApi";
import { CustomerType } from "../../../redux/features/customers/types";
import { APP } from "../../../_shared/utils/_urls";
import {
  formatAudit,
  formatShortDate,
  friendlyName,
} from "../../../_shared/utils/uiHelpers";

import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormInfo from "../../../_shared/components/FormInfo";
import UpdateCustomerModal from "./components/UpdateCustomerModal";
import DeleteCustomerModal from "./components/DeleteCustomerModal";
import ResetPasswordModal from "./components/ResetPasswordModal";
import AddAppointmentModal from "../../appointments/components/AppointmentsAdd";
import AppointmentsForCustomer from "./components/Appointments";
import { isNull } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../redux/hooks";
import CustomerForms from "./components/Forms";
import OrdersGrid from "./components/OrdersGrid";
import OrdersFilter from "./components/OrdersFilter";
import _ from "lodash";
import AuditGrid from "./components/AuditGrid";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const statusOptions = [
  { value: "Created", label: "Created" },
  { value: "InProgress", label: "In Progress" },
  { value: "Complete", label: "Complete" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Other", label: "Other" },
];

const CustomerDetailPage = () => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() - 30))
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() + 1))
  );
  const [statuses, setStatuses] = useState<any[]>(
    _.filter(statusOptions, (so) => {
      return so.value !== "Created";
    })
  );
  const [showAddAppointmentModal, setShowAddAppointmentModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);

  const onUpdateFilter = (filter: any | undefined) => {
    console.log(filter);
    setStatuses(filter.statuses);
    setStartDate(filter.startDate);
    setEndDate(filter.endDate);
  };

  const [customer, setCustomer] = useState<CustomerType>();
  const params = useParams();
  const navigate = useNavigate();
  const {
    data = undefined,
    isFetching,
    isSuccess,
    refetch,
  } = useGetCustomerQuery(params.customer_id ?? skipToken);
  const [value, setValue] = React.useState(0);

  const getCustomerType = () => {
    return customer?.user_type === null ? "Customer" : customer?.user_type;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddAppointmentModal(true);
  };

  const onDoneAdding = () => {
    setShowAddAppointmentModal(false);
  };

  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditProfileModal(true);
  };

  const onResetPassword = (e: any) => {
    e.preventDefault();
    setShowResetPasswordModal(true);
  };

  const onDoneEditing = (reload: boolean) => {
    setShowEditProfileModal(false);
    if (reload) {
      refetch();
    }
  };

  const onDoneResettingPassword = (show: boolean) => {
    setShowResetPasswordModal(show);
  };

  const onDelete = (e: any) => {
    e.preventDefault();
    setShowDeleteCustomerModal(true);
  };

  const onDoneDeleting = (show: boolean) => {
    setShowDeleteCustomerModal(show);
  };

  useEffect(() => {
    if (isSuccess) {
      setCustomer(data);
    }
  }, [data]);

  if (customer !== undefined) {
    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Breadcrumbs>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    navigate(`${APP.CUSTOMERS}`);
                  }}
                >
                  Customers
                </Link>
                <Typography color="text.primary">
                  {friendlyName(customer?.first_name, customer?.last_name)}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Stack direction="row">
                <Button
                  variant="text"
                  color="secondary"
                  onClick={onResetPassword}
                >
                  Reset Password
                </Button>
                <Button variant="text" color="secondary" onClick={onEdit}>
                  Edit
                </Button>
                <Button variant="text" color="secondary" onClick={onDelete}>
                  Delete
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                {friendlyName(customer?.first_name, customer?.last_name)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {getCustomerType()}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Chip
                sx={{ mt: 1 }}
                label={customer?.is_verified ? "Verified" : "Not Verified"}
                color={customer?.is_verified ? "success" : "error"}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInfo label="Email" value={customer?.email} />
              <FormInfo label="Phone" value={customer?.phone_number} />
              {customer?.user_type === "Admin" && (
                <FormInfo label="Role" value={customer?.role} />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <List>
                <ListItem>
                  <ListItemText
                    primary={formatAudit(
                      customer?.created_by_name,
                      customer?.created_at,
                      "Created"
                    )}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={formatAudit(
                      customer?.updated_by_name,
                      customer?.updated_at,
                      "Updated"
                    )}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4 }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Appointments" {...a11yProps(0)} />
              <Tab label="Profile Information" {...a11yProps(1)} />
              <Tab label="Forms" {...a11yProps(2)} />
              <Tab label="Orders" {...a11yProps(3)} />
              <Tab label="Audit" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid
              item
              sm={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="text" color="secondary" onClick={onAdd}>
                + Add
              </Button>
            </Grid>
            <AppointmentsForCustomer customer_id={customer?.id} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormInfo
                  label="Gender"
                  value={customer?.customer_profile?.gender}
                />
                <FormInfo
                  label="Date of Birth"
                  value={formatShortDate(customer?.customer_profile?.dob)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInfo
                  label="Address Line 1"
                  value={customer?.customer_profile?.address_line_1}
                />
                <FormInfo
                  label="City"
                  value={customer?.customer_profile?.city}
                />
                <FormInfo
                  label="Address Line 2"
                  value={
                    customer?.customer_profile?.address_line_2 !== ""
                      ? customer?.customer_profile?.address_line_2
                      : "n/a"
                  }
                />
                <FormInfo
                  label="State/Postal Code"
                  value={`${
                    customer?.customer_profile?.state !== undefined
                      ? customer?.customer_profile?.state
                      : "--"
                  }, ${
                    customer?.customer_profile?.postal_code !== undefined
                      ? customer?.customer_profile?.postal_code
                      : "--"
                  }`}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CustomerForms customer_id={customer?.id} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <OrdersFilter
              statusOptions={statusOptions}
              statuses={statuses}
              startDate={startDate}
              endDate={endDate}
              changeHandler={onUpdateFilter}
            />
            <OrdersGrid
              customerId={customer.id}
              statuses={statuses}
              startDate={startDate}
              endDate={endDate}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AuditGrid customerId={customer.id} />
          </TabPanel>
        </Container>
        <UpdateCustomerModal
          customerToUpdate={customer as CustomerType}
          doneEditing={onDoneEditing}
          show={showEditProfileModal}
        />
        <DeleteCustomerModal
          customerToDelete={customer as CustomerType}
          doneDeleting={onDoneDeleting}
          show={showDeleteCustomerModal}
        />
        <ResetPasswordModal
          customerToResetPassword={customer as CustomerType}
          doneResettingPassword={onDoneResettingPassword}
          show={showResetPasswordModal}
        />
        <AddAppointmentModal
          doneAdding={onDoneAdding}
          show={showAddAppointmentModal}
          customer={customer.id}
        />
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default CustomerDetailPage;
