import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserTestsQuery } from "../../../../../redux/features/tests/testsApi";
import { TestType } from "../../../../../redux/features/tests/types";
import Link from "@mui/material/Link";
import { APP } from "../../../../../_shared/utils/_urls";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import React from "react";
import { UserSignedFormType } from "../../../../../redux/features/forms/types";
import { useGetFormsByCustomerQuery } from "../../../../../redux/features/forms/formsApi";

type CustomerFormsProps = {
  customer_id: string;
};

const CustomerForms = (props: CustomerFormsProps) => {
  const [forms, setForms] = useState<UserSignedFormType[]>([]);
  const columns: GridColDef[] = [
    {
      field: "form_name",
      headerName: "Form",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Link
            onClick={() => {
              window.open(
                `https://app.hellosign.com/editor/view/super_group_guid/${params.row.signature_request_id}`,
                "_blank"
              );
            }}
          >
            {params.value}
          </Link>
        </React.Fragment>
      ),
    },
    {
      field: "form_type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "customer_signed",
      headerName: "Customer Signed",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return `${params.value ? "Yes" : "No"}`;
      },
    },
    {
      field: "customer_signed_timestamp",
      headerName: "When Signed",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.customer_signed_timestamp !== undefined
            ? formatDateTime(params.row.customer_signed_timestamp)
            : "--"
        }`;
      },
    },
    {
      field: "physician_name",
      headerName: "Physician",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Link
            onClick={() => {
              window.open(
                `https://admin.fitnescity.com/forms/sign?email=${encodeURIComponent(
                  params.row.physician_email
                )}&signature_request_id=${params.row.physician_signature_id}`,
                "_blank"
              );
            }}
          >
            {params.value}
          </Link>
        </React.Fragment>
      ),
    },
  ];
  const {
    data = [],
    isFetching,
    isSuccess,
  } = useGetFormsByCustomerQuery(props.customer_id);

  useEffect(() => {
    if (isSuccess) {
      setForms(data);
    }
  });

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={forms}
          columns={columns}
          loading={isFetching}
        />
      </div>
    </div>
  );
};

export default CustomerForms;
