import { PartnerType } from "../../../../../redux/features/partners/types";
import {
  useGetPartnersQuery,
  useUpdatePartnerMutation,
} from "../../../../../redux/features/partners/partnersApi";
import { useFormik } from "formik";
import {
  Button,
  Stack,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { merge } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";

type UpdatePartnerProps = {
  partnerToUpdate: PartnerType | Record<string, any>;
  show: boolean;
  doneEditing: (reload: boolean) => void;
};

const UpdatePartnerModal = (props: UpdatePartnerProps) => {
  const handleClose = () => {
    props.doneEditing(false);
  };
  const [partnerToUpdate, setPartnerToUpdate] = useState<
    PartnerType | Record<string, any>
  >();
  const [updatePartner, { isLoading }] = useUpdatePartnerMutation();
  const { refetch } = useGetPartnersQuery({});

  const submitHandler = (values: any) => {
    const payload = merge({}, partnerToUpdate, values);
    updatePartner(payload)
      .unwrap()
      .then(() => {
        refetch();
        formik.setSubmitting(false);
        props.doneEditing(true);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: partnerToUpdate?.name || "",
      abbreviation: partnerToUpdate?.abbreviation || "",
      phone_number: partnerToUpdate?.phone_number || "",
      address_line_1: partnerToUpdate?.address_line_1 || "",
      address_line_2: partnerToUpdate?.address_line_2 || "",
      city: partnerToUpdate?.city || "",
      state: partnerToUpdate?.state || "",
      postal_code: partnerToUpdate?.postal_code || "",
      timezone: partnerToUpdate?.timezone || "",
      other_identifier: partnerToUpdate?.other_identifier || "",
      dropbox_path: partnerToUpdate?.dropbox_path || "",
      contact_email: partnerToUpdate?.contact_email || "",
    },
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setPartnerToUpdate(props.partnerToUpdate);
  }, [props.partnerToUpdate]);

  if (formik !== undefined) {
    return (
      <Dialog
        open={props.show}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Update Partner Information</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Stack spacing={2} sx={{ mt: 3 }}>
              <TextField
                error={Boolean(formik.touched.name && formik.errors.name)}
                fullWidth
                label="Name"
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.name}
              />
              <TextField
                error={Boolean(
                  formik.touched.abbreviation && formik.errors.abbreviation
                )}
                fullWidth
                label="Abbreviation"
                name="abbreviation"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.abbreviation}
              />
              <TextField
                error={Boolean(
                  formik.touched.phone_number && formik.errors.phone_number
                )}
                fullWidth
                label="Phone"
                name="phone_number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.phone_number}
              />
              <TextField
                error={Boolean(
                  formik.touched.address_line_1 && formik.errors.address_line_1
                )}
                fullWidth
                label="Address Line 1"
                name="address_line_1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.address_line_1}
              />
              <TextField
                error={Boolean(
                  formik.touched.address_line_2 && formik.errors.address_line_2
                )}
                fullWidth
                label="Address Line 2"
                name="address_line_2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address_line_2}
              />
              <TextField
                error={Boolean(formik.touched.city && formik.errors.city)}
                fullWidth
                label="City"
                name="city"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.city}
              />
              <TextField
                error={Boolean(formik.touched.state && formik.errors.state)}
                fullWidth
                label="State"
                name="state"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.state}
              />
              <TextField
                error={Boolean(
                  formik.touched.postal_code && formik.errors.postal_code
                )}
                fullWidth
                label="Postal Code"
                name="postal_code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.postal_code}
              />
              <TextField
                error={Boolean(
                  formik.touched.timezone && formik.errors.timezone
                )}
                fullWidth
                label="Timezone"
                name="timezone"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.timezone}
              />
              <TextField
                error={Boolean(
                  formik.touched.other_identifier &&
                    formik.errors.other_identifier
                )}
                fullWidth
                label="Other Identifier"
                name="other_identifier"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.other_identifier}
              />
              <TextField
                error={Boolean(
                  formik.touched.dropbox_path && formik.errors.dropbox_path
                )}
                fullWidth
                label="Dropbox Folder Path"
                name="dropbox_path"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.dropbox_path}
              />
              <TextField
                error={Boolean(
                  formik.touched.contact_email && formik.errors.contact_email
                )}
                fullWidth
                helperText="Separate multiple emails with ','"
                label="Contact Email"
                name="contact_email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contact_email}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              disabled={isLoading}
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  } else {
    return <p>Error</p>;
  }
};

export default UpdatePartnerModal;
