import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetOrdersQuery } from "../../../../../redux/features/commerce/orders/ordersApi";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { Box, Link } from "@mui/material";
import { APP } from "../../../../../_shared/utils/_urls";
import { CheckoutOrderType } from "../../../../../redux/features/commerce/orders/types";
import React from "react";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";
import { formatISO9075 } from "date-fns";
import _ from "lodash";

type OrdersGridProps = {
  statuses: any[] | undefined;
  startDate: Date;
  endDate: Date;
};

const OrdersGrid = (props: OrdersGridProps) => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<CheckoutOrderType[]>([]);

  const columns: GridColDef[] = [
    {
      field: "order_ref",
      headerName: "Order Ref",
      width: 125,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Link
            onClick={() => {
              navigate(`${APP.ORDERS}/${params.row.id}`);
            }}
          >
            {params.value}
          </Link>
        </React.Fragment>
      ),
    },
    // {
    //   field: "stripe_id",
    //   headerName: "Stripe Id",
    //   width: 300,
    //   renderCell: (params: GridRenderCellParams) => (
    //     <React.Fragment>
    //       <Link
    //         onClick={() => {
    //           window.open(
    //             `https://dashboard.stripe.com/payments/${params.value}`,
    //             "_blank"
    //           );
    //         }}
    //       >
    //         {params.value}
    //       </Link>
    //     </React.Fragment>
    //   ),
    // },
    {
      field: "user_id",
      headerName: "Customer",
      flex: 1,
      width: 150,
      valueGetter: (params) => {
        return params.row.user_name ? params.row.user_name : "Unknown";
      },
    },
    {
      field: "user_email",
      headerName: "Email",
    },
    {
      field: "description",
      headerName: "Description",
      valueGetter: (params) => {
        return `${params.row.order_ref} - ${
          params.row.user_name ? params.row.user_name : "Unknown"
        } -  ${params.row.product_type}`;
      },
    },
    {
      field: "product_id",
      headerName: "Product",
      flex: 1,
      valueGetter: (params) => {
        return params.row.product_name;
      },
    },
    { field: "status", headerName: "Status" },
    { field: "original_amount", headerName: "Original Amount" },
    { field: "promo_code", headerName: "Promo Code" },
    { field: "promo_code_discount_amount", headerName: "Discount Amount" },
    { field: "total", headerName: "Total", width: 100 },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.created_at)} by ${
          params.row.created_by_name
        }`;
      },
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
  ];

  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetOrdersQuery({
    statuses: _.map(props.statuses, (s) => {
      return `'${s.value}'`;
    }),
    startDate: formatISO9075(props.startDate),
    endDate: formatISO9075(props.endDate),
  });

  useEffect(() => {
    if (isSuccess) {
      setOrders(data);
    }
  });

  return (
    <Box style={{ display: "flex", height: "100%" }}>
      <Box style={{ flexGrow: 1 }}>
        <DataGridPro
          initialState={{
            columns: {
              columnVisibilityModel: {
                updated: false,
                original_amount: false,
                user_email: false,
                description: false,
              },
            },
          }}
          autoHeight
          density="compact"
          disableRowSelectionOnClick={true}
          rows={orders}
          columns={columns}
          loading={isFetching}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default OrdersGrid;
