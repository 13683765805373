import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetAuditsResultType, AuditType } from "./types";
import { store } from "../../store";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const auditApi = createApi({
  reducerPath: "auditApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAuditByCustomer: builder.query<AuditType[], string>({
      query(customerId: string) {
        return {
          url: `audit/customer/${customerId}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetAuditsResultType) => {
        return result.data;
      },
    }),
    getByAppointment: builder.query<AuditType[], {}>({
      query() {
        return {
          url: "audit/appointment",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetAuditsResultType) => {
        return result.data;
      },
    }),
  }),
});

export const { useGetAuditByCustomerQuery } = auditApi;
