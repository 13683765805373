import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import {
  useGetPendingVo2Query,
  useGetTestsQuery,
} from "../../../../redux/features/tests/testsApi";
import { TestType } from "../../../../redux/features/tests/types";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../../../_shared/utils/uiHelpers";
import {
  GridFilterItem,
  GridLogicOperator,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { Box, Link } from "@mui/material";
import { APP } from "../../../../_shared/utils/_urls";
import _ from "lodash";
import { formatISO9075 } from "date-fns";

type PendingVo2GridProps = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  refetch: boolean;
  finishRefetch: () => void;
};

const PendingVo2Grid = (props: PendingVo2GridProps) => {
  const navigate = useNavigate();
  const [tests, setTests] = useState<TestType[]>([]);

  const columns: GridColDef[] = [
    {
      field: "appointment_date",
      headerName: "Appointment",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          onClick={() => {
            navigate(`${APP.APPOINTMENTS}/${params.row.id}`);
          }}
        >
          {params.row.appointment_date
            ? formatDateTime(params.row.appointment_date)
            : "Not Scheduled"}
        </Link>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "test_type",
      headerName: "Test",
      flex: 1,
      valueFormatter: (params) => {
        return params.value === null ? "Customer" : params.value;
      },
    },
    {
      field: "partner_name",
      headerName: "Partner",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.value === "CompleteAndSend"
          ? "Complete and Sent"
          : params.value;
      },
    },
    {
      field: "locked",
      headerName: "Locked",
      valueGetter: (params) => {
        return `${params.row.locked === true ? "Yes" : "No"}`;
      },
    },
  ];
  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetPendingVo2Query({
    startDate:
      props.startDate !== undefined
        ? formatISO9075(props.startDate)
        : undefined,
    endDate:
      props.endDate !== undefined ? formatISO9075(props.endDate) : undefined,
  });

  useEffect(() => {
    if (isSuccess) {
      setTests(data);
      props.finishRefetch();
    }
  });

  useEffect(() => {
    if (props.refetch) {
      refetch();
    }
  }, [props.refetch]);

  return (
    <Box style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={tests}
          columns={columns}
          loading={isFetching}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
    </Box>
  );
};

export default PendingVo2Grid;
