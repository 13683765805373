import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetAuditByCustomerQuery } from "../../../../../redux/features/audit/auditApi";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { Box, Link } from "@mui/material";
import { APP } from "../../../../../_shared/utils/_urls";
import React from "react";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";
import { formatISO9075 } from "date-fns";
import _ from "lodash";
import { AuditType } from "../../../../../redux/features/audit/types";

type AuditGridProps = {
  customerId: string;
};

const AuditGrid = (props: AuditGridProps) => {
  const navigate = useNavigate();
  const [audits, setAudits] = useState<AuditType[]>([]);

  const columns: GridColDef[] = [
    {
      field: "change_type",
      headerName: "Type",
      flex: 1,
      width: 150,
    },
    {
      field: "original_value",
      headerName: "Original",
    },
    {
      field: "new_value",
      headerName: "New",
    },
    {
      field: "updated_by_name",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.change_timestamp)} by ${
          params.row.updated_by_name
        }`;
      },
    },
  ];

  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetAuditByCustomerQuery(props.customerId);

  useEffect(() => {
    if (isSuccess) {
      setAudits(data);
    }
    console.log(audits);
  });

  return (
    <Box style={{ display: "flex", height: "100%" }}>
      <Box style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          density="compact"
          disableRowSelectionOnClick={true}
          rows={audits}
          columns={columns}
          loading={isFetching}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default AuditGrid;
