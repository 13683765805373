import { Box, FormControlLabel, Stack, Switch } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type FilterProps = {
  startDate: Date | null;
  endDate: Date | null;
  changeHandler: (filter: any) => void;
};

const AppointmentsFilter = (props: FilterProps) => {
  const handleBeginDateChange = (startDate: Date | null) => {
    const filter = {
      startDate: startDate,
      endDate: props.endDate,
    };
    props.changeHandler(filter);
  };

  const handleEndDateChange = (endDate: Date | null) => {
    const filter = {
      startDate: props.startDate,
      endDate: endDate,
    };
    props.changeHandler(filter);
  };
  return (
    <Stack direction="row" spacing={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          sx={{ width: "400px" }}
          label="Start Date"
          value={props.startDate}
          onChange={(newValue) => handleBeginDateChange(newValue)}
        />

        <DatePicker
          sx={{ width: "400px" }}
          label="End Date"
          value={props.endDate}
          onChange={(newValue) => handleEndDateChange(newValue)}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default AppointmentsFilter;
